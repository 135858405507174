import Splide from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import gsap from "gsap";

export function initializeSplide() {
  document.addEventListener("DOMContentLoaded", function () {
    var splideReferenzenElement2 = document.querySelector(".splide.referenzen");
    var splideLogosHeader = document.querySelectorAll(".splide-logos-header");
    var themenSplide = document.querySelectorAll(".projekt-themen-container");

  
    if (splideLogosHeader.length > 0) {
      splideLogosHeader.forEach((element) => {
        var splideTesti = new Splide(element, {
          autoWidth: true,
          autoScroll: {
            speed: 0.5
          },
          type: "loop",
          drag: "free",
          arrows: false,
          focus: "start",
          perPage: 4,
          perMove: 1,
          gap: "2rem",
          pagination: false,
          breakpoints: {
            1024: {
              perPage: 2,
              gap: "1rem"
            }
          }
        });
        splideTesti.mount({ AutoScroll });
      });
    }

    if (splideReferenzenElement2) {
      var splideReferenzen = new Splide(splideReferenzenElement2, {
        type: "loop",
        // autoplay: true,
        autoHeight: true,
        drag: true,
        perPage: 1,
        perMove: 1,
        arrows: false,
        gap: "1rem",
        pagination: false
      });
      splideReferenzen.mount();

      var forwardButton = document.querySelector(".splide-left");
      var backwardButton = document.querySelector(".splide-right");

      forwardButton.addEventListener("click", function () {
        splideReferenzen.go("<");
      });

      backwardButton.addEventListener("click", function () {
        splideReferenzen.go(">");
      });

      //watch when splide is moved
      splideReferenzen.on("move", function () {
        const counterReferenzen = splideReferenzenElement2.querySelectorAll(
          ".ref-number.counter-selector-animation"
        );

        counterReferenzen.forEach((counter) => {
          gsap.from(counter, {
            textContent: 0,
            duration: 1,
            snap: { textContent: 1 },
            scrollTrigger: {
              toggleActions: "restart none reverse none",
              trigger: counter,
              start: "top 80%",
              end: "bottom 100%"
            },
            onComplete: () => {
              counter.innerHTML = counter.innerHTML + "+";
            }
          });
        });
      });
    }

    if (themenSplide.length > 0) {
      themenSplide.forEach((element) => {
        var splideThemen = element.querySelector(".projekt-themen");
        var splideThemenMount = new Splide(splideThemen, {
          type: "loop",
          drag: true,
          perPage: 4,
          perMove: 1,
          start: 0,
          arrows: false,
          gap: "0rem",
          pagination: false,
          breakpoints: {
            1024: {
              perPage: 2,
              gap: "0rem"
            },
            768: {
              perPage: 1,
              gap: "0rem"
            }
          }
        });
        splideThemenMount.mount();
        var forwardButton = element.querySelector(".increase-one");
        forwardButton.addEventListener("click", function () {
          splideThemenMount.go(">");
        });
      });
    }
  });
}


