import "./styles/main.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { initModals } from "./scripts/modals.js";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger.js";
import { initializeSplide } from "./scripts/splide.js";
import { initLazyLoad } from "./scripts/lazyload.js";
import { initAnimations } from "./scripts/animations.js";
import { initForms } from "./scripts/forms.js";
import { playVideo } from "./scripts/playvideo.js";
// import { initBento } from "./scripts/bento.js";
import { handleResize } from "./scripts/resize.js";

initializeSplide();
gsap.registerPlugin(ScrollTrigger);

document.addEventListener("DOMContentLoaded", () => {
  initLazyLoad(); // Initialize Lazy Loading
  // initBento(); // Initialize BentoGrid
  initForms(); // Initialize Form Validations
  initModals(); // Initialize Modals and Popups
  initAnimations();
  playVideo();
});

window.addEventListener("load", () => {
  AOS.refresh();
  setTimeout(() => {
    AOS.refresh();
  }, 1000);
});

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
}

window.scrollToTop = scrollToTop;

document.addEventListener("click", (e) => {
  if (e.target.tagName === "A" || e.target.tagName === "BUTTON") {
    const href = e.target.getAttribute("href");
    if (href && href.startsWith("#")) {
      e.preventDefault();
      const target = document.querySelector(href);
      if (target) {
        target.scrollIntoView({
          behavior: "smooth"
        });
      }
    }
  }
});




//watch the scroll position and show/hide the button #scroll-to-top-button
window.addEventListener("scroll", () => {
  const button = document.getElementById("scroll-to-top-button");
  if (button) {
    if (window.scrollY > 300) {
      button.classList.remove("hidden");
    } else {
      button.classList.add("hidden");
    }
  }
}); 

