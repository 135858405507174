import AOS from "aos";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function initAnimations() {
  AOS.init({
    once: true,
    startEvent: "load"
  });

  const globalCounter = document.querySelectorAll(
    ".counter-selector-animation"
  );

  const headerCounter = document.querySelectorAll(".header-counter-number");
  if (headerCounter) {
    headerCounter.forEach((counter) => {
      gsap.from(counter, {
        textContent: 0,
        duration: 1,
        stagger: 0,
        snap: { textContent: 1 },
        onComplete: () => {
          counter.innerHTML = counter.innerHTML + "+";
        }
      });
    });
  }

  let resizeTimer;
  window.addEventListener("resize", () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      ScrollTrigger.refresh(); // Call refresh only after the resize event is stable
    }, 200);
  });

  if (globalCounter.length > 0) {
    globalCounter.forEach((counter) => {
      gsap.from(counter, {
        textContent: 0,
        duration: 1,
        snap: { textContent: 1 },
        scrollTrigger: {
          toggleActions: "restart none none none",
          trigger: counter,
          start: "top 80%",
          end: "bottom 100%"
        },
        onComplete: () => {
          counter.innerHTML = counter.innerHTML + "+";
        }
      });
    });
  }

  const windAnimation = document.querySelector(".wind-animation");
  if (windAnimation) {
    var paths = windAnimation.querySelectorAll("path");
    paths.forEach((path, index) => {
      var l = path.getTotalLength();
      gsap.set(path, { strokeDasharray: l });
      gsap.set(path, { strokeDashoffset: -l });
      gsap.to(path, 0.5, { strokeDashoffset: 0, delay: index * 0.1 });
    });
  }

  const gifElement = document.getElementById("myGif");

  if (gifElement) {
    const originalSrc = gifElement.src;
    ScrollTrigger.create({
      trigger: ".gif-container",
      start: "top 80%",
      onEnter: () => {
        gifElement.src = originalSrc;
      }
    });
  }

  const pfeilRaus = document.querySelectorAll(".luft-raus-pfeile svg path");
  const pfeilRein = document.querySelectorAll(".luft-rein-pfeile svg path");

  if (pfeilRaus.length > 0) {
    gsap.from(pfeilRaus, {
      x: 200,
      opacity: 0,
      duration: 0.5,
      stagger: 0.05,
      scrollTrigger: {
        toggleActions: "restart none reverse none",
        trigger: pfeilRaus,
        start: "top 50%",
        end: "bottom 100%"
      }
    });

    gsap.from(pfeilRein, {
      x: -200,
      opacity: 0,
      duration: 0.5,
      stagger: 0.05,
      scrollTrigger: {
        toggleActions: "restart none reverse none",
        trigger: pfeilRaus,
        start: "top 50%",
        end: "bottom 100%"
      }
    });
  }
}
